<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center" no-gutters>
			<v-col cols="12" :sm="step === 2 ? '11' : '12'" class="pr-4">
				<base-heading :title="title">
					<base-btn v-if="showMainReport" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
			</v-col>
			<v-col cols="12" sm="1" align="center">
				<base-btn v-if="step === 2" color="grey darken-3" text @click="close" icon="mdi-arrow-left" btnText="Atras"></base-btn>
      </v-col>
    </v-row>

		<v-row>
			<v-col cols="12">
				<v-window v-model="step" touchless>
					<v-window-item :value="1">
						<v-form ref="form">
							<v-row justify="center" no-gutters>
								<v-col cols="12" sm="3" md="3" lg="3" xl="3" class="mx-0 mx-sm-3">
									<base-select v-model="editedSearchItem.branchOfficeId" :items="branchOfficesAvailable" label="Sucursal origen" 
									clearable></base-select>
								</v-col>
								<v-col v-if="showSearchTargetBranchOffice" cols="12" sm="3" md="3" lg="3" xl="3" class="mx-0 mx-sm-3">
									<base-select v-model="editedSearchItem.targetBranchOfficeId" :items="filteredBranchOfficesAvailable" label="Sucursal destino" 
									clearable></base-select>
								</v-col>
								<v-col cols="12" sm="4" md="3" lg="3" xl="3" class="mx-0 mx-sm-3 mb-4 mb-sm-0">
									<base-date-range-picker-dialog @range="dateRange" :rules="[commonValRules.required]" required/>
								</v-col>
								<v-col cols="12" sm="4" md="3" lg="3" xl="3" class="mx-0 mx-sm-3">
									<base-select v-model="editedSearchItem.inventoryMovementTypeId" :items="inventoryMovementTypes" label="Tipo de movimiento"
									clearable></base-select>
								</v-col>
								
								<v-col cols="12" sm="2" md="2" lg="2" xl="2" align="center">
									<base-btn dark color="teal" class="mt-2" @click="toList()" icon="mdi-table-search" btnText="Buscar" :loading="loadingSearchForm"></base-btn>
								</v-col>
							</v-row>
						</v-form>

						<v-row>
							<v-col cols="12">
								<base-data-table 
									:loading="loadingSearchForm"
									loading-text="Cargando datos..."
									v-model="sortBy"
									desc
									:headers="headers" 
									:items="inventoryMovements" 
									hideNewItemButton
									@loadDataTable="toList"
								>
									<template v-slot:item.options="{ item }">
										<base-icon-btn color="info darken-1" @click="viewItem(item)" icon="mdi-eye" tooltip="Ver Detalle"></base-icon-btn>
										<base-icon-btn v-if="item.statusId!==$Const.INVENTORY_MOVEMENT_STATUS.CANCELLED" color="green darken-1" @click="reportView(item)" icon="mdi-file-chart" tooltip="Ver Informe"></base-icon-btn>
									</template>

									<template v-slot:item.status="{ item }">
										<template v-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.REGISTERED">
											<span class="blue--text">{{item.status}}</span>
										</template>
										<template v-else-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.SHIPPED">
											<span class="secondary--text">{{item.status}}</span>
										</template>
										<template v-else-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.DELIVERED">
											<span class="secondary--text">{{item.status}}</span>
										</template>
										<template v-else-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.APPROVED">
											<span class="success--text">{{item.status}}</span>
										</template>
										<template v-else>
											<span class="red--text">{{item.status}}</span>
										</template>
									</template>

									<template v-slot:item.dateTime="{ item }">
										<base-form-date-list v-if="item.inventoryMovementTypeId===$Const.INVENTORY_MOVEMENT_TYPE.DISTRIBUTION"
											:dateTimeToShow="item.dateTime" 
											:showDialog="item.showDateTimeDialog"
											:formTitle="`Información movimiento: ${item.id}`"
											:headers="dateTimeHeaders"
											:items="item.dateTimeItems"
											@open="openDateTimeDialog(item)"
											@close="closeDateTimeDialog(item)"
										></base-form-date-list>
									</template>
								</base-data-table>
							</v-col>
						</v-row>
					</v-window-item>
					<v-window-item :value="2">
						<base-form-simple ref="InventoryMovementForm"
							:formTitle="inventoryMovementFormTitle"
							hideSaveButton
							@cancelButton="close"
						>
							<template v-slot:formContent>
								<v-container class="no-padding" fluid>
									<v-row justify="center">
										<v-col v-if="showReason" cols="12">
											<v-alert
												text
												elevation="2"	
												color="red"
												transition="scale-transition"
											>
												<v-icon color="red" left>mdi-alert-circle</v-icon>
												<strong>El movimiento fue anulado por el siguiente motivo: </strong> {{editedItem.reason}}
											</v-alert>
										</v-col>

										<!-- Detalles del movimiento -->
										<v-col cols="12" sm="6" md="6">
											<base-form-container
												title="Datos del movimiento"
												titleClasses="grey lighten-3"
											>
												<template v-slot:content>
													<v-row align="center" justify="center">
														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.id" label="Nro. de Movimiento" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.dateTime" label="Fecha y hora" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.status" label="Estado" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.inventoryMovementType" label="Tipo" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="8" xlg="6">
															<base-text-field v-model="editedItem.user" label="Responsable" readonly>
															</base-text-field>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Sucursales -->
										<v-col cols="12" sm="6" md="6">
											<base-form-container
												title="Sucursales"
												titleClasses="grey lighten-3"
											>
												<template v-slot:content>
													<v-row justify="center">
														<v-col cols="12" sm="6" md="6">
															<base-text-field v-model="editedItem.branchOffice" label="Origen" readonly>
															</base-text-field>
														</v-col>
														<v-col v-if="showTargetBranchOffice" cols="12" sm="6" md="6">
															<base-text-field v-model="editedItem.targetBranchOffice" label="Destino" readonly>
															</base-text-field>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Detalles de los productos -->
										<v-col cols="12">
											<base-form-container
												title="Productos"
												titleClasses="info darken-1 white--text"
											>
												<template v-slot:content>
													<v-row align="center" justify="center">
														<v-col cols="12" align="center">
															<base-data-table ref="DtDetails"
																:headers="detailHeaders" 
																:items="details"
																sortBy="product"
																hideHeader
																:show-expand="showExpand"
																item-key="productId"
																elevation="0"
																showBorder
															>
																<template v-slot:expanded-item="{ item }">
																	<base-stock-details-picker :ref="`StockForm${item.productId}`" :productId="item.productId" :stockFormId="item.stockFormId" :stockGroup="item.movementDetails" :detailMode="detailMode">
																	</base-stock-details-picker>
																</template>

																<template v-slot:item-data-table-expand="{ expand, isExpanded, item }">
																	<transition name="fade" mode="out-in">
																		<base-icon-btn v-if="isExpanded" :size="25" min-elevation="0" key="hide" @click="expand(closeStockFormDetails(isExpanded, item))" color="red darken-1" icon="mdi-eye-off" tooltip="Ocultar Detalles"></base-icon-btn>
																		<base-icon-btn v-else :size="25" min-elevation="0" key="show" @click="expand(!isExpanded)" color="success darken-1" icon="mdi-eye" tooltip="Ver Detalles"></base-icon-btn>
																	</transition>
																</template>

																<template v-slot:item.image="{ item }">
																	<div style="width:100px;">
																		<base-product-img v-model="item.image" :productId="item.productId"
																			class="my-2"
																			gradient="to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.07)"
																			aspect-ratio="1.5"
																			contain
																		>
																		</base-product-img>
																	</div>
																</template>

																<template v-slot:item.quantity="{ item }">
																	<v-icon small>mdi-cart-variant</v-icon> {{ item.quantity }}
																</template>

																<template v-slot:no-data>
																	<h3 class="red--text">*No hay productos agregados al detalle.</h3>
																</template>
															</base-data-table>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Datos finales -->
										<v-col cols="12" sm="6" order-sm="2" align="right">
											<base-form-container
												title="Datos finales"
												titleClasses="info darken-1 white--text"
											>
												<template v-slot:titleContent>
													<v-spacer></v-spacer>
													<v-badge color="red" overlap :content="calculateTotalProductsForBadge" :value="calculateTotalProductsForBadge">
														<v-icon dark>mdi-cart-variant</v-icon>
													</v-badge>
												</template>

												<template v-slot:content>
													<v-row align="center" justify="center" no-gutters>
														<v-col cols="6" sm="6" align="center">
															<h3 class="mt-6 mt-sm-0">
																<strong>Productos en Total</strong>
																<p class="mt-2 mb-0">{{calculateTotalProducts}}</p>
															</h3>
														</v-col>
														<v-col cols="12" class="pt-4">
															<base-textarea outlined v-model="editedItem.comments" label="Comentarios" maxlength="250" 
															readonly></base-textarea>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>
									</v-row>
								</v-container>
							</template>
						</base-form-simple>
					</v-window-item>
				</v-window>
			</v-col>
		</v-row>

		<base-pdf-viewer :show="showPdfViewer" :title="reportFile.title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],
	
	data() {
		return {
			title:'Reporte de inventario',
			step: 1,
			sortBy: 'dateTime',
			headers: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Nro. Movimiento", value: "id" },
        { text: "Usuario", value: "userFullName" },
				{ text: "Tipo", value: "inventoryMovementType" },
				{ text: "Origen", value: "branchOffice" },
				{ text: "Destino", value: "targetBranchOffice" },
				{ text: "Fecha", value: "dateTime" },
        { text: "Estado", value: "status" }
			],
			inventoryMovements: [],
			detailHeaders: [
				{ text: "Imagen", value: "image", sortable: false },
				{ text: "Código", value: "customCode", sortable: false },
				{ text: "Producto", value: "product", sortable: false },
        { text: "Cantidad", value: "quantity", sortable: false },
				{ text: 'Detalles', value: 'data-table-expand' },
			],
			details: [],
			showExpand: true,
			inventoryMovementTypes:[],
			editedItem: {
				id: '',
				inventoryMovementTypeId:'',
				branchOfficeId:'',
				targetBranchOfficeId: '',
				comments: '',

				user: '',
				branchOffice:'',
				inventoryMovementType:'',
				targetBranchOffice: '',
				dateTime: '',
				statusId: 0,
				status: '',
				reason: '',
      },
      defaultItem: {
       	id: '',
				inventoryMovementTypeId:'',
				branchOfficeId:'',
				targetBranchOfficeId: '',
				comments: '',

				user: '',
				branchOffice:'',
				inventoryMovementType:'',
				targetBranchOffice: '',
				dateTime: '',
				statusId: 0,
				status: '',
				reason: '',
			},
			branchOfficesAvailable: [],
			editSourceBranchOffice: true,
			formLoading: false,
			viewDetails:false,
			//SEARCH FORM
			branchOffices:[],
			editedSearchItem: {
				branchOfficeId: '',
				targetBranchOfficeId: '',
				startDate:'',
				endDate:'',
				inventoryMovementTypeId:''
			},
			selectedItem: {
				branchOfficeId: '',
				targetBranchOfficeId: '',
				startDate:'',
				endDate:'',
				inventoryMovementTypeId:''
			},
			defaultSearchItem: {
				branchOfficeId: '',
				targetBranchOfficeId: '',
				startDate:'',
				endDate:'',
				inventoryMovementTypeId:''
			},
			loadingSearchForm:false,
			//INVENTORY DATE TIMES
			dateTimeHeaders: [
				{ text: "Nro", value: "row", sortable: false },
				{ text: "Usuario", value: "userFullName", sortable: false },
				{ text: "Fecha y hora", value: "dateTime", sortable: false },
        { text: "Estado", value: "status", sortable: false },
				{ text: "Comentario", value: "comments", sortable: false }
			],
			//PDF comprobante
			showPdfViewer: false,
			reportFile:{
				title: '',
				fileName:'',
				fileContent:''
			},
			defaultReportFile:{
				title: '',
				fileName:'',
				fileContent:''
			},
		}
	},

	computed: {
		inventoryMovementFormTitle() {
			return 'Movimiento';
		},

		detailMode() {
				return 'ShowStockMovement';
		},

		filteredBranchOfficesAvailable() {
			if (this.editedSearchItem.branchOfficeId == '') {
				return this.branchOfficesAvailable;
			}
			return this.branchOfficesAvailable.filter(f => f.value != this.editedSearchItem.branchOfficeId);
		},

		showMainReport(){
			if (this.step === 2 || this.inventoryMovements.length<=0) {
				return false;	
			}
			return true;
		},

		calculateTotalProducts(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? result : 0;
		},

		calculateTotalProductsForBadge(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? (result > 99 ? result = '+99' : result) : 0;
		},

		showReason(){
			var result = this.viewDetails && this.editedItem.statusId===this.$Const.INVENTORY_MOVEMENT_STATUS.CANCELLED;
			return result;
		},

		showTargetBranchOffice(){
			if (this.editedItem.inventoryMovementTypeId == this.$Const.INVENTORY_MOVEMENT_TYPE.DISTRIBUTION) {
				return true;
			}
			return false;
		},

		showSearchTargetBranchOffice(){
			if (!!this.editedSearchItem.inventoryMovementTypeId && this.editedSearchItem.inventoryMovementTypeId != 1) {
				this.editedSearchItem.targetBranchOfficeId = '';
				return false;
			}
			return true;
		},
	},

	created() {
		this.listOfBranchOffices();
		this.listOfInventoryMovementTypes();
	},
	
	methods: {
		dateRange(dates = []){
			if (dates == null) {
				this.editedSearchItem.startDate = this.defaultSearchItem.startDate;
				this.editedSearchItem.endDate = this.defaultSearchItem.endDate;
			}
			else {
				this.editedSearchItem.startDate = dates[0];
				this.editedSearchItem.endDate = dates[1];
			}
		},

		async toList(){
			if (!this.$refs.form.validate()) {
        return;
			}
			let me=this;
			try {
        me.SHOW_LOADING()
				me.inventoryMovements=[];
				me.loadingSearchForm=true;
				me.selectedItem = Object.assign({}, me.defaultSearchItem);
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.editedSearchItem.branchOfficeId,
					'targetBranchOfficeId': me.editedSearchItem.targetBranchOfficeId,
					'startDate': me.editedSearchItem.startDate,
					'endDate': me.editedSearchItem.endDate,
					'inventoryMovementTypeId': !!me.editedSearchItem.inventoryMovementTypeId ? parseInt(me.editedSearchItem.inventoryMovementTypeId, 10) : 0
				};
				await me.getObjectResponse('api/InventoryMovement/GetReportByFilter', request).then(data => {
					if (data !== undefined) {
						me.inventoryMovements = me.addCustomProperties(data.inventoryMovements);
						me.selectedItem = Object.assign({}, me.editedSearchItem);
					}
				}).catch(function(error){
				  me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.loadingSearchForm=false;
      }
		},

		addCustomProperties(originalInventoryMovements) {
			let inventoryMovements = originalInventoryMovements;
			inventoryMovements.map(x => {
				x.showDateTimeDialog = false;
				x.dateTimeItems = [];
			});
			return inventoryMovements;
		},

		async listOfBranchOffices(){
      let me=this;
			var branchOfficesArray=[];
			await me.getObjectResponse('api/BranchOffice/Select', me.baseRequest).then(data => {
				if (data === undefined) {
					return;
				}
				branchOfficesArray = data.branchOffices; 
				branchOfficesArray.map(function(x){
					me.branchOfficesAvailable.push({text:x.visualName, value:x.id});
				});
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},

		async listOfInventoryMovementTypes(){
      let me=this;
			var inventoryMovementTypesArray=[];
			await me.getObjectResponse('api/Parameter/SelectAllInventoryTypes').then(data => {
				if (data === undefined) {
					return;
				}
				inventoryMovementTypesArray = data.inventoryMovementTypes; 
				inventoryMovementTypesArray.map(function(x){
					me.inventoryMovementTypes.push({text:x.visualName, value:x.id});
				});
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},

		parseStockDetails(details, stockFormId, serialize) {
			var result = []
			let cloneDetails = details.slice();
			if (serialize) {
				cloneDetails.map((x) => {
					let stockDetailsAvailable = x.stockDetails.filter(f => f.stock !== null && f.stock && parseInt(f.stock) > 0).map(x => x);
					if (stockDetailsAvailable.length <= 0) {
						return;
					}
					stockDetailsAvailable = stockDetailsAvailable.map((x) => {
						const res  = Object.fromEntries(Object.entries(x).filter(e=>e[0]!=='originalStock'));
						return res;
					});
					let stockValues = stockDetailsAvailable.map(x => parseInt(x.stock)).reduce((a,b) => a+b);
					if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
						result.push({ 
							purchaseId:x.purchaseId,
							stock:stockValues,
							stockDetails:null
						});
					} else {
						let stockDetails = JSON.stringify(stockDetailsAvailable);
						result.push({ 
							purchaseId:x.purchaseId,
							stock:stockValues,
							stockDetails:stockDetails
						});
					}
				});
			} else {
				cloneDetails.map((x) => {
					let stockDetails = []
					if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
						stockDetails.push({
							id:1,
							stock:x.stock
						});
					} else {
						stockDetails = JSON.parse(x.stockDetails);
					}

					for (let i = 0; i < stockDetails.length; i++) {
						const element = stockDetails[i];
						if (!this.viewDetails) {
							element.originalStock = element.stock
							element.stock = 0
						}
					}

					result.push({ 
						purchaseId:x.purchaseId,
						stock:x.stock,
						stockDetails:stockDetails
					});
				});
			}
			return result;
		},

		setDetails(details){
			var result = [];
			for (let i = 0; i < details.length; i++) {
				const element = details[i];
				var stockFormId = parseInt(element.stockFormId, 10)
				var movementDetails = JSON.parse(element.movementDetails)
				var movementDetailsResult = this.parseStockDetails(movementDetails, stockFormId, false)
				
				result.push({
					id: element.id,
					productId: element.productId,
					image: null,
					customCode: element.customCode,
					product: element.product,
					quantity: parseInt(element.quantity, 10), 

					stockFormId: stockFormId,
					movementDetails: movementDetailsResult,
				});
			}
			return result;
		},

		closeStockFormDetails(isExpanded, item) {
			if (this.viewDetails) {
				return !isExpanded;
			}

			var form = this.$refs[`StockForm${item.productId}`];
			if (!form.validateStockFormDetails()) {
				return isExpanded;
			}
			return !isExpanded;
		},

		async viewItem(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getObjectResponse('api/InventoryMovement/GetById', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.viewDetails=true;
					me.editedItem = Object.assign({}, data.inventoryMovement);
					me.details = me.setDetails(data.inventoryMovement.details);
					me.step++;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    close() {
			this.step=1;
			this.clean();
    },
    
    clean(){
			if (this.$refs.DtDetails) {
				this.$refs.DtDetails.collapseAll();	
			}
			this.$refs.InventoryMovementForm.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
			this.productCode="";
			this.details = [];
			this.viewDetails=false;
			this.editSourceBranchOffice = true;
    },

		async openDateTimeDialog(item){
			let me=this;
			try {
        me.SHOW_LOADING()
				item.dateTimeItems=[];
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};

				await me.getObjectResponse('api/InventoryMovement/GetDatesById', request).then(data => {
					if (data !== undefined) {
						item.dateTimeItems = data.dateTimeList;
						item.showDateTimeDialog=true;
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    closeDateTimeDialog(item) {
			item.showDateTimeDialog=false;
			item.dateTimeItems=[];
    },

		async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.selectedItem.branchOfficeId,
					'targetBranchOfficeId': me.editedSearchItem.targetBranchOfficeId,
					'startDate': me.selectedItem.startDate,
					'endDate': me.selectedItem.endDate,
					'inventoryMovementTypeId': !!me.selectedItem.inventoryMovementTypeId ? parseInt(me.selectedItem.inventoryMovementTypeId, 10) : 0
				};
				await me.getObjectResponse('api/InventoryMovement/GetFilteredReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = 'Reporte de movimientos';
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async reportView(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getObjectResponse('api/InventoryMovement/GetReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = `Movimiento ${item.id}`;
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
			if (this.step===2) {
				this.close();
			}
		},
	},
}
</script>